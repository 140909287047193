import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "popover" ]

  connect() {
    this.popoverTargets.forEach(target=>{
      $(target).on('show.bs.popover', () => {
        this.popoverTargets.forEach(popover=>{
          if(popover == target){
            return
          }
          $(popover).popover("hide")
        })
      })
    })
  }
  disconnect() {
  }
}
