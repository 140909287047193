import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["result", "addend"]

  connect() {
    // This collects all of the keys attached to each resultTarget in the view
    let keysArr = this.resultTargets.map((el) => { return this._keysForEl(el, "result") }) // Double check this
    let keys = [].concat.apply([], keysArr)
    keys.forEach((key) => { this.recalculateTotalForKey(key) })
  }

  recalculateTotal(e) {
    let addendKeys = this._keysForEl(e.target, "addend")
    addendKeys.forEach((key) => {
      this.recalculateTotalForKey(key)
    })
  }

  recalculateTotalForKey(key) {
    // Regex from here: https://stackoverflow.com/a/2901298
    let result = this._sumResultForKey(key).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    this.resultTargets.filter((el) => { return this._keysForEl(el, "result").indexOf(key) !== -1 })
                      .forEach((el) => {
                        el.innerHTML = `$${result}`
                        this._triggerRecalculateForAddends(el)
                      })
  }

  _sumResultForKey(key) {
    let sum = this.addendTargets.filter((el) => { return this._keysForEl(el, "addend").indexOf(key) !== -1 })
                             // Either grab element value or innerText and drop dollar sign
                             .map((el) => { return el.value || el.innerText.substring(1) })
                             .map((val) => { return parseFloat(val.replaceAll(",", "")) })
                             .filter((float) => { return !isNaN(float) })
                             .reduce((a, b) => { return a + b }, 0) // Do a sum on all the numbers
    return Math.round(sum * 100) / 100
  }

  _triggerRecalculateForAddends(el) {
    let addendKeys = this._keysForEl(el, "addend")
    if (addendKeys.length == 0) {
      return
    }

    addendKeys.forEach((key) => { this.recalculateTotalForKey(key) })
  }

  _keysForEl(el, type) {
    let keys = []
    if (type) {
      keys = keys.concat(JSON.parse(el.dataset[`${type}Keys`] || "[]"))
    }
    keys = keys.concat(JSON.parse(el.dataset.keys || "[]"))
    return keys
  }
}