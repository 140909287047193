import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "htmlContent" ]

  connect() {
    let options = {}
    if (this.hasHtmlContentTarget) {
      options.html = true
      options.content = this.htmlContentTarget
    }
    $(this.element).popover(options)
  }
  disconnect() {
    $(this.element).popover('dispose')
  }
}
