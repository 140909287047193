// This is a copy of the implementation from app/javascript/keen_v1.4.4/theme/demo5/src/assets/js/global/layout/offcanvas-panel.js
// but it has been ported to work for a stimulus controller

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var head = KTUtil.find(this.element, '.kt-offcanvas-panel__head');
    var body = KTUtil.find(this.element, '.kt-offcanvas-panel__body');

    let offcanvasPanelPrefix = this.data.get('prefix')

    var offcanvas = new KTOffcanvas(this.element, {
        overlay: true,  
        baseClass: 'kt-offcanvas-panel',
        closeBy: `${offcanvasPanelPrefix}_close`,
        toggleBy: `${offcanvasPanelPrefix}_toggler_btn`
    }); 

    KTUtil.scrollInit(body, {
        disableForMobile: true, 
        resetHeightOnDestroy: true, 
        handleWindowResize: true, 
        height: () => {
            var height = parseInt(KTUtil.getViewPort().height);
          
            if (head) {
                height = height - parseInt(KTUtil.actualHeight(head));
                height = height - parseInt(KTUtil.css(head, 'marginBottom'));
            }
    
            height = height - parseInt(KTUtil.css(this.element, 'paddingTop'));
            height = height - parseInt(KTUtil.css(this.element, 'paddingBottom'));    

            return height;
        }
    });
  }
}