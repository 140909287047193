window.KTAppOptions = {
  "colors": {
    "state": {
      "brand": "#1cac81",
      "metal": "#c4c5d6",
      "light": "#ffffff",
      "accent": "#00c5dc",
      "primary": "#5867dd",
      "success": "#34bfa3",
      "info": "#36a3f7",
      "warning": "#ffb822",
      "danger": "#fd3995",
      "focus": "#9816f4"
    },
    "base": {
      "label": [
        "#b9bdc1",
        "#aeb2b7",
        "#414b4c",
        "#343d3e"
      ],
      "shape": [
        "#eef4f3",
        "#e0e9e6",
        "#80c3af",
        "#41675c"
      ]
    }
  }
};

require("./keen_v1.4.4/theme/demo5/tools/webpack/vendors/global")
require("./keen_v1.4.4/theme/demo5/tools/webpack/scripts")
require("./keen_v1.4.4/theme/demo5/tools/webpack/styles")
require("./keen_v1.4.4/theme/demo5/src/assets/sass/global/layout/header/skins/navy")
require("./keen_v1.4.4/theme/demo5/src/assets/sass/pages/login/login-v2")
require("keen.scss")
