import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggle", "item"]
  
  connect() {
    this.toggleTarget.addEventListener('input', () => {
      this.itemTargets.forEach((check) => {
        check.checked = this.toggleTarget.checked
      })
    })

    this.itemTargets.forEach((item) => {
      item.addEventListener('input', (e) => { this._updateToggleFromItems(e) })
    })
  }

  _updateToggleFromItems(e) {
    if (!e.target.checked) {
      this.toggleTarget.checked = false
    }
  }
}
