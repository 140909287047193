import { Controller } from "stimulus"
import Sentry from 'sentry';

// Here we place tinymce skins in the proper lookup location so it can find them
require.context(
  '!file-loader?name=js/[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
  true,
  /.*/
)

import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/image'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import "tinymce/plugins/code"

import '../tinymce_placeholder'

export default class extends Controller {
  connect() {
    var includeCode = this.element.dataset.includeCode
    tinymce.init({
      selector: 'textarea',
      target: this.element,
      plugins: "autolink link image table lists code",
      menubar: "insert tools",
      toolbar: `${includeCode == "true" ? "code |" : "" } styleselect | fontselect | bold italic underline | link | alignleft aligncenter alignright alignjustigy | outdent indent | numlist bullist| forecolor backcolor`,
      height: "500",
      images_upload_url: "/api/tinymce_image_uploads",
      images_upload_handler: function (blobInfo, success, failure, progress) {
        var xhr, formData;
    
        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', '/api/tinymce_image_uploads');
    
        xhr.upload.onprogress = function (e) {
          progress(e.loaded / e.total * 100);
        };
    
        xhr.onload = function() {
          var json;

          var image_size = blobInfo.blob().size / 1000; // image size in kbytes
          var max_size = 20000 // max size in kbytes
          if( image_size > max_size ){        
            failure('Image is too large ('+ (image_size / 1000) + 'MB), maximum image size is 20MB');
            return;      
          }
    
          if (xhr.status < 200 || xhr.status >= 300) {
            failure('HTTP Error: ' + xhr.status);
            return;
          }
    
          json = JSON.parse(xhr.responseText);
    
          if (!json || typeof json.location != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
          }
    
          success(json.location);
        };
    
        xhr.onerror = function () {
          failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
        };

        xhr.setRequestHeader("X-CSRF-Token", document.head.querySelector('meta[name="csrf-token"]').content)
    
        formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        xhr.send(formData);
      },
      convert_urls: false,
      // Don't allow img tags to be nested inside various inline tags
      // In practice this forces TinyMCE to push the inserted image out of the current parent tag
      // ....into a new <p> tag ¯\_(ツ)_/¯. But at least we get img tags in their own element
      // We have a function on the server to remove the wrapping <p> and give us plain <img> tags
      // See `html_fixer.rb#unnest_img_tags`
      valid_children: "-p[img],-h1[img],-h2[img],-h3[img],-h4[img],-h5[img],-h6[img]",
      invalid_elements: "div",
      entity_encoding: "raw"
    }).catch((error) => { 
      console.error(error)
      Sentry.captureException(error)
    })
      
  }

  disconnect() {
    tinymce.editors.find(tinymce =>{
      return tinymce.targetElm == this.element
    }).destroy()
    // After destroy the textarea is still left at `display: none`, we will reset it here
    this.element.style.display = ''
  }
}
