import { Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.add('fade')
    this.element.classList.add('show')
    setTimeout(() => {
      this.element.classList.remove('show')
    }, this.data.get('delay'))
  }
}