import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.addCommas(this.element)
  }

  commaize(e) {
    this.addCommas(e.target)
  }

  addCommas(el) {
    let commalessValue = el.value.replaceAll(",", "")
    // Regex from here: https://stackoverflow.com/a/2901298
    el.value = commalessValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}
