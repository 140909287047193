import { Controller } from "stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  connect() {
    $(this.element).on('ajax:before', this.beforeAjax.bind(this))
    $(this.element).on('ajax:success', this.handleAjax.bind(this))
    $(this.element).on('ajax:error', this.showError.bind(this))
  }

  disconnect() {
    $(this.element).off('ajax:before', this.beforeAjax)
    $(this.element).off('ajax:success', this.handleAjax)
    $(this.element).off('ajax:error', this.showError)
  }

  beforeAjax(e) {
    Swal.fire({
      title: 'Processing!',
      text: 'Generating your report...',
      type: 'info',
      showConfirmButton: false
    })
    Swal.showLoading()
  }

  handleAjax(e) {
    const [data, status, xhr] = e.detail
    const subscription = Cable.subscriptions.create({ channel: "DownloadsChannel", download_id: data.download_id }, {
      received: (data) => {
        Swal.hideLoading()
        if (data.error) {
          this.showError()
        } else {
          Swal.fire({
            title: 'Finished!',
            text: 'Your download is ready',
            type: 'success',
            showConfirmButton: true,
            confirmButtonText: "Download"
          }).then(() => {
            if (typeof Trestle != 'undefined') {
              Trestle.visit(data.url)
            } else {
              Turbolinks.visit(data.url)
            }
          })
        }

        subscription.unsubscribe()
      }
    })
  }

  showError() {
    Swal.hideLoading()
    Swal.update({
      title: "Error!",
      text: "There was an issue generating your download",
      type: "error",
      showConfirmButton: true
    })
  }
}