import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toInput", "toOutput", "ccInput", "ccOutput", "bodyInput", "bodyOutput"]

  updatePreview() {
    this.toOutputTarget.textContent = this.toInputTarget.value
    this.ccOutputTarget.textContent = this.ccInputTarget.value
    this.bodyOutputTarget.textContent = this.bodyInputTarget.value
  }
}